import { Box, Card, Typography, SxProps, Theme } from '@mui/material';
import { FormCheckbox } from 'components';
import { Control, FieldErrors } from 'react-hook-form';

export interface IPageCheckboxProps {
  control: Control;
  name: string;
  errors: FieldErrors;
  defaultValue: boolean;
  heading: string | undefined;
  description: string | undefined;
  dataCy: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

export const PageCheckbox: React.FC<IPageCheckboxProps> = ({
  control,
  name,
  errors,
  defaultValue,
  heading,
  description,
  dataCy,
  disabled,
  sx = {},
  onClick,
}) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        backgroundColor: '#fafafa',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        py: 2,
        pr: 2,
        pl: 1,
        mb: 3,
        gap: 2,
        ...sx,
      }}
      variant="outlined"
    >
      <FormCheckbox
        sx={{ ml: 1.5, mr: -1.5 }}
        color="info"
        control={control}
        name={name}
        errors={errors}
        defaultValue={defaultValue as unknown as string}
        data-cy={dataCy}
        disabled={disabled}
      />
      <Box>
        <Typography variant="body1">{heading}</Typography>
        <Typography variant="caption" color="text.secondary">
          {description}
        </Typography>
      </Box>
    </Card>
  );
};
